import { useEffect, useState } from "react";
import {
  BuilderComponent,
  Builder,
  builder,
  useIsPreviewing,
} from "@builder.io/react";
import ErrorPage from "./components/ErrorPage";
import "./index.css";

// Put your API key here
builder.init("743ed1a8ec5d41eab5abba36861352aa");

Builder.register("editor.settings", {
  styleStrictMode: true, // optional
  designTokens: {
    colors: [
      { name: "Brand Red", value: "var(--red, #ff0000)" },
      { name: "Brand Blue", value: "rgba(93, 150, 255, 1)" },
    ],
    fontFamily: [
      { name: "Serif Font", value: "var(--serif-font, Times, serif)" },
      { name: "Primary Font", value: "Roboto, sans-serif" },
    ],
  },
});
// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
export default function CatchAllRoute() {
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);

  // get the page content from Builder
  useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get("page", {
          url: window.location.pathname,
        })
        .promise();

      setContent(content);
      setNotFound(!content);

      // if the page title is found,
      // set the document title
      if (content?.data.title) {
        document.title = content.data.title;
      }
    }
    fetchContent();
  }, [window.location.pathname]);

  // If no page is found, return
  // a 404 page from your code.
  // The following hypothetical
  // <FourOhFour> is placeholder.
  if (notFound && !isPreviewingInBuilder) {
    return <ErrorPage />;
  }

  // return the page when found
  return (
    <>
        <BuilderComponent
          model="page"
          content={content}
        />
    </>
  );
}
